import React from 'react'
import './Component11.css'
export default function Component11() {
    return (
        <>
            <div className='one_container_inside_component11'>
                <h1>Important Notes</h1>
                <h2>1.Domain, Hosting & Google Play Console Fees:</h2>
                <p>The costs for domain registration, web hosting, and Google Play Console  account fees are not included in the pricing plans and are the responsibility of the  client. We provide assistance in setting up these services, including step-by-step  guidance, if required.</p>
                <h2>2.GST Information  :</h2>
                <p>The prices listed above do not include Goods and Services Tax (GST). The total  cost for each package will be calculated as the listed price plus an additional  18% GST. We will provide a detailed invoice, and the GST amount will be clearly  outlined.</p>
                 <h2>3.Payment Terms  :</h2>
                 <p>A 25% advance payment of the total project cost is required to commence work.  The remaining balance will be divided into installments based on project  milestones. A detailed payment schedule will be provided.</p>
                <h2>4.Disclaimer  :</h2>
                <p>The prices listed above are general estimates and may vary depending on the  specific requirements and complexity of the project. Additional features,  customizations, or unique situations may lead to adjustments in the final pricing.  A detailed and customized quote will be provided after a thorough assessment of  your needs.</p>
                 <h2>5.Asset Provision Disclaimer:</h2>
                 <p>All required assets, such as images, videos, logos, and content, are to be  provided by the client. These materials should be delivered in the necessary  formats and resolutions to ensure optimal integration into the project. If  assistance is needed in sourcing or creating assets, additional costs may apply.</p>


            </div>



        </>
    )
}
