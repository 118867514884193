import React from 'react'
import './Container2.css'
import android_web_development_img2 from '../assets/images/android_web_development_img2.png'
import logo_white from '../assets/images/logo_white.png'
import website_dev_img1 from '../assets/images/website_dev_img1.png'
import android_app_development_image from '../assets/images/android_app_development_image.png'
import CardA from './CardA'
export default function Container2() {
    return (
        <>
            <div className="first_section_container2">
                <div className='section1_first_section_container2'>
                    <h1 className='a_h2_first_section_container2'>Transforming your vision into reality</h1>
                    <p className='a_para_first_section_container2'>
                        "Driving Business Growth through Web Development, Android Apps Development, Graphics Designing, and Strategic Digital Marketing."
                    </p>
                </div>
                <div className='section2_first_section_container2'>
                    <img src={android_web_development_img2} alt="" className='a_img_first_section_container2' />
                </div>

            </div>
            <div className='second_section_container2'>
                <CardA title="Website  Development" />
                <CardA title="Android App Development" />
            </div>
            <div className='third_section_container2'>
                <img src={logo_white} alt="Logo" className='img_third_section_container2' />
            </div>
            <div className='fourth_section_container2'>
                <div className='one_inside_fourth_section_container2'>
                    <div className='two_inside_one_inside_fourth_section_container2'>
                        <h1>“Your Vision, Our Innovation: Building the Future Together.”</h1>
                    </div>
                    <div className='three_inside_one_inside_fourth_section_container2'>
                        <p>At GlassHopeOne Technologies, our website development services are tailored to create responsive, user-friendly sites that represent your brand's unique identity and captivate your audience. We specialize in Android app development, crafting intuitive, high-performance apps designed to elevate your business and enhance user engagement. Our digital marketing strategies are data-driven and designed to increase your online visibility, attract potential clients, and boost conversions. Meanwhile, our innovative graphics designing team focuses on creating stunning visuals that convey your brand message effectively, leaving a lasting impression. At GlassHopeOne Technologies, we seamlessly blend creativity with technology, turning your ideas into impactful digital solutions that drive growth and success.
                        </p>
                    </div>
                </div>
            </div>
            <div className='fifth_section_container2'>
                <h2>Our Services</h2>
            </div>
            <div className='sixth_section_container2'>
                <div className='first_inside_sixth_section_container2'>
                    <h2>Website Development
                    </h2>
                </div>
                <div className='second_inside_sixth_section_container2'>
                    <div className='one_second_inside_sixth_section_container2'>
                        <img src={website_dev_img1} className='imga_one_second_inside_sixth_section_container2' alt="website-development-image" />
                    </div>
                    <div className='two_second_inside_sixth_section_container2'>
                        <h3>“We Specialize in creating a variety of  Web Applications tailored to your Business need.”
                        </h3>
                    </div>
                </div>
            </div>
            <div className='seventh_section_container2'>
                <div className='one_inside_seventh_section_container2'>
                    <h2>Our Expertise :-
                    </h2>
                </div>
                <div className='two_inside_seventh_section_container2'>
                    <h1>
                        1. Corporate Website
                    </h1>
                    <p>
                        “Establish a powerful online presence with a corporate website that perfectly mirrors your brand and drive business growth.”

                    </p>
                    <h1>
                        2. Portfolio Website
                    </h1>
                    <p>
                        “Highlight your work and attract new clients with a stunning, easy to navigate portfolio website.”

                    </p>
                    <h1>
                        3. Product showcase  Website
                    </h1>
                    <p>
                        “Showcase your products with visually striking designs that captivate and convert visitors.”

                    </p>
                    <h1>
                        4. Landing Pages for Marketing Campaigns
                    </h1>
                    <p>
                        “Optimize your marketing efforts with focused landing pages designed to maximize conversion.”

                    </p>
                    <h1>
                        5. E-commerce Platforms
                    </h1>
                    <p>
                        “We build secure, scalable and user friendly e-commerce websites that help you sell your products online efficiently.”

                    </p>
                    <h1>
                        6.SAAS Applications
                    </h1>
                    <p>
                        “We develops software as a service (SAAS) solutions that offer seamless experiences, where it’s for B2B or B2C customers.”

                    </p>
                    <h1>
                        7. Custom Solutions
                    </h1>
                    <p>
                        “We provide custom web app development services to create solutions uniquely design to meet your business challenges.”

                    </p>
                    <h1>
                        8. We develop both
                    </h1>
                    <p>
                        “Static and Dynamic Websites.”

                    </p>
                </div>

            </div>
            <div className='eight_section_container2'>
                <div className='one_inside_eight_section_container2'>
                    <h1>Our Development Process</h1>
                </div>
                <div className='two_inside_eight_section_container2'>
                    <h1>
                        1.Understanding your Business and Needs
                    </h1>
                    <p>
                        “We begin by discussing your goals, target audience and specific requirements to ensure that the website aligns perfectly with your business strategy.”
                    </p>
                    <h1>
                        2.Design & Layout
                    </h1>
                    <p>
                        “Our Design team creates a clean, modern layout that reflects your brand and appeals to your target audience. We prioritize user experience to make your website incentive and easy to use.”
                    </p>
                    <h1>
                        3.Developement
                    </h1>
                    <p>
                        “Using cutting-edge technologies (React, Nodejs, Mongo DB), we bring the design to life, our websites are responsive, ensuring they look great on all the devices from desktop to smart phone.
                    </p>
                    <h1>
                        4.Testing & Quality Assurance
                    </h1>
                    <p>
                        “We rigorously test your web app to ensure it is bug free,secure and performs well under various conditions.”
                    </p>
                    <h1>
                        5.Deployment & Maintenance
                    </h1>
                    <p>
                        “Once the app is ready, we handle the deployment on your chosen platform and private ongoing support and maintenance to keep it running smoothly.”
                    </p>
                </div>
            </div>
            <div className='nine_section_container2'>
                <h1 className='a_h1_nine_section_container2'>Technologies We use :</h1>
                <div className='two_inside_nine_section_container2'>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>Nodejs</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>React</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>MongoDB</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>HTML</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>CSS</h1>
                    </div>

                </div>
                <div className='three_inside_nine_section_container2' >

                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>JavaScript</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>PHP</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>MySQL</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>AWS</h1>
                    </div>
                    <div className='container_inside_two_inside_nine_section_container2'>
                        <h1>Figma</h1>
                    </div>

                </div>
            </div>

            <div className='ten_section_container2'>
                <div className='one_inside_ten_section_container2'>
                    <h1>Android App Development</h1>
                </div>
                <div className='two_inside_ten_section_container2'>
                    <div className='first_two_inside_ten_section_container2'>
                        <h1>Android App  Development
                        </h1>
                    </div>
                    <div className='second_two_inside_ten_section_container2'>
                        <img src={android_app_development_image} alt="android_app_development_image" />
                    </div>
                </div>
                <div className='three_inside_ten_section_container2'>
                    <p>
                        “We specialize in developing customized native Android applications that  cater to the unique demands of businesses at every stage of growth. Our  focus is on delivering high-impact mobile solutions that drive user  engagement and streamline business processes, ensuring your app not only  meets but exceeds your business objectives

                    </p>
                </div>

            </div>
            <div className='eleven_section_container2'>
                <div className='one_inside_eleven_section_container2'>
                    <h1>Our Expertise :-
                    </h1>
                </div>
                <div className='two_inside_eleven_section_container2'>
                    <h1>1.Custom Android App Development</h1>
                    <p>Creating personalized apps tailored to your  specific business needs.
                    </p>
                    <h1>2. User-Centric Design</h1>
                    <p> Designing intuitive, user-friendly interfaces that enhance  engagement and satisfaction.
                    </p>
                    <h1> 3.Performance Optimization</h1>
                    <p>Ensuring apps run smoothly with high efficiency and  minimal resource usage
                    </p>
                    <h1> 4.API Integration</h1>
                    <p>Seamlessly integrating third-party services and APIs to extend app  functionality..
                    </p>
                    <h1>5.Security Features</h1>
                    <p> Implementing robust security measures to protect user data and  ensure compliance.
                    </p>
                    <h1> 6.Scalable Solutions</h1>
                    <p>Developing apps that can grow with your business,  accommodating increasing users and features.</p>
                    <h1>7.Google Play Console Management</h1>
                    <p>Handling app deployment, updates, and analytics  through the Google Play Console.</p>
                    <h1>8.Maintenance and Support</h1>
                    <p>Providing ongoing support and updates to keep your app  running optimally.</p>
                </div>
            </div>
            <div className='twelve_section_container2'>
                <h1 className='a_h1_twelve_section_container2'>Technologies We use :</h1>
                <div className='two_inside_twelve_section_container2'>
                    <h1>
                        Android Studio
                    </h1>
                    <h1>Java</h1>
                    <h1> XML</h1>
                    <h1> Firebase</h1>
                    <h1> Nodejs</h1>

                </div>
            </div>




        </>
    )
}
