import React from 'react'
import './CardA.css'
export default function CardA(props) {
  return (
   <>
   <div className="container1_card">
         <h1 className='a_h1_container1_card'>{props.title}</h1>
   </div>
   
   
   
   </>
  )
}
