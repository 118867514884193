import React from 'react'
import './WebsiteMaintenance.css'
import Header from '../components/Header'

import Footer from '../components/Footer'
import Container12 from '../components/Container12'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function WebsiteMaintenance() {
  return (
  <>
  <Header/>
  <Container12/>
  <WhatsappButton/>
  <CallButton/>
  <Footer/>
  
  
  </>
  )
}
