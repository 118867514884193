import React from 'react'
import './Container5.css'
import website_development_service_image_a from '../assets/images/website_development_service_image_a.png'
export default function Container5() {
  return (
    <>
      <div className='one_section_container5'>
        <h1 className='a_h1_one_section_container5'>Our Services</h1>
        <div className='first_inside_one_section_container5'>
          <h1>Web App Development</h1>
          <h3>(Website Development)</h3>
        </div>
      </div>
      <div className='two_section_container5'>
        <div className='first_two_section_container5'>
          <p>
            At GlassHopeOne Technologies, we specialize in crafting  high-performance web applications that are scalable,  secure, and tailored to meet the specific needs of your  business. Our expert team combines modern technologies  with industry best practices to deliver seamless user  experiences that drive engagement and growth.
          </p>
        </div>
        <div className='second_two_section_container5'>
          <h1>(Website Development)</h1>
          <img src={website_development_service_image_a} alt="website_development_service_image_a" className='img_1_second_two_section_container5' />
        </div>
        <div className='third_two_section_container5'>
          <h1 className='one_inside_third_two_section_container5'>Technologies We Use :</h1>
          <div className='two_inside_third_two_section_container5'>
            <div className='first_inside_two_inside_third_two_section_container5'>
              <h1>Frontend</h1>
              <div className='second_inside_first_inside_two_inside_third_two_section_container5'>
                <ul>
                  <li>React</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                </ul>
              </div>
            </div>
            <div className='first_inside_two_inside_third_two_section_container5'>
              <h1>Backend</h1>
              <div className='second_inside_first_inside_two_inside_third_two_section_container5'>
                <ul>
                  <li>Node.js</li>
                  <li>Express.js</li>
                  <li>MongoDB</li>
                  <li>MySQL,PHP</li>
                </ul>
              </div>
            </div>
            <div className='first_inside_two_inside_third_two_section_container5'>
              <h1>Deployment</h1>
              <div className='second_inside_first_inside_two_inside_third_two_section_container5'>
                <ul>
                  <li>AWS</li>
                  <li>Hostinger</li>
                  <li>Godaddy</li>
                  <li>any other</li>
                </ul>
              </div>
            </div>


          </div>
        </div>

      </div>



    </>
  )
}
