
import React from 'react'
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Container1 from '../components/Container1';
import Container2 from '../components/Container2';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function Home() {
  return (
    <>
      <Helmet>
        <title>Glasshopeone Technologies Pvt. Ltd.,Bhubaneswar,India</title>
        <meta name="description" content="Best Website and Android App development Company in Bhubaneswar,India" />
        <meta name="keywords" content="glasshopeone,glasshopeone technologies,glasshopeone technologies private limited,
        website development company,android app development company,website development company in bhubaneswar
        ,android app development company in bhubaneswar, graphics designing company in bhubaneswar, website development company in odisha" />
        <meta name="author" content="Glasshopeone Technologies Private Limited" />
        <link rel="canonical" href="https://www.yourwebsite.com/path" />

      </Helmet>
      <Header />
      <Container1 />
      <Container2 />
      <WhatsappButton />
      <CallButton />
      <Footer />

    </>


  )
}
