import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import DemoComponent from '../components/DemoComponent'
export default function Blog() {
  return (
    <>
   <Header/>
   <DemoComponent/>
   <Footer/>


   
   
   
   </>
  )
}
