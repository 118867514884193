import React from 'react'
import './Container14.css';
import {
    Link
  } from "react-router-dom";
import e_learning_gho_1 from '../assets/images/e_learning_gho_1.jpg';
import realestate_gho_1 from '../assets/images/realestate_gho_1.jpg';
import ecommerce_gho1 from '../assets/images/ecommerce_gho1.jpg';
import travel_booking_gho_1 from '../assets/images/travel_booking_gho_1.jpg';
import event_management_gho_1 from '../assets/images/event_management_gho_1.jpg';
import food_delivery_gho_1 from '../assets/images/food_delivery_gho_1.jpg';

export default function Container14() {
    
  return (
   <>
   <div className='first_container_1_Container14'>
    <h1>Our Works</h1>
    <div className='one_inside_first_container_1_Container14'>
        <div className='first_inside_one_inside_first_container_1_Container14'>
            <h1>Real Estate</h1>
            <img src={realestate_gho_1} alt="Real Estate" />
            <button ><Link to="/realestate-projects" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bold' }} >Explore</Link></button>
        </div>
        <div className='first_inside_one_inside_first_container_1_Container14'>
            <h1>E-commerece</h1>
            <img src={ecommerce_gho1} alt="E-Commerce" />
            <button>Explore</button>
        </div>
        <div className='first_inside_one_inside_first_container_1_Container14'>
            <h1>E-learning</h1>
            <img src={e_learning_gho_1} alt="E-Learning" />
            <button>Explore</button>
        </div>
        <div className='first_inside_one_inside_first_container_1_Container14'>
            <h1>Travel Booking</h1>
            <img src={travel_booking_gho_1} alt="Travel Booking" />
            <button>Explore</button>
        </div>
        <div className='first_inside_one_inside_first_container_1_Container14'>
            <h1>Event Management</h1>
            <img src={event_management_gho_1} alt="Event Management" />
            <button>Explore</button>
        </div>
        <div className='first_inside_one_inside_first_container_1_Container14'>
            <h1>Food Delivery</h1>
            <img src={food_delivery_gho_1} alt="Event Management" />
            <button>Explore</button>
        </div>

    </div>
   </div>
   </>
  )
}
