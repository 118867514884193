import React from 'react'
import './AndroidAppMaintenance.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Container13 from '../components/Container13'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function AndroidAppMaintenance() {
  return (
   <>
   <Header/>
   <Container13/>
   <WhatsappButton/>
   <CallButton/>
   <Footer/>


   
   
   
   </>

  )
}
