import React from 'react'
import'./CallButton.css'
import phone_icon from '../assets/images/phone_icon.png'
export default function CallButton() {
    const phoneNumber = "7852965155";
  return (
    <div className="call-button">
      <a
        href={`tel:${phoneNumber}`}
        className="call_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={phone_icon}
          alt="Call"
          className="call-icon"
        />
      </a>
    </div>
  )
}
