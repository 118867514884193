import React from 'react'
import background1 from '../assets/images/background1.png'
import img1 from '../assets/images/img1.png'
import './Container1.css'
import Contactform from './Contactform'
export default function Container1() {
  return (
    <>
      <div className='a_container'>
        <img src={background1} className='bg1' alt="" />
        <img src={img1} className='bg1_img1' alt="" />
        <div className='first_custom_container_a_container'>
          <div className="tag1_a_container">
            <h2 className='a_h2'> <span style={{ color: 'white', fontWeight: 'bold' }}>"</span>Innvovative</h2>
            <h1 className='a_h1'>Web App</h1>
            <h1 className='b_h1'>& Android App</h1>
            <h2 className='b_h2'>Development"</h2>
          </div>
          <div className="tag2_a_container">
            <h3>"Glass of Hope for Everyone in the Digital World"
            </h3>
          </div>
          <div className="tag3_a_container">
            <h3>Glasshopeone Technologies Pvt. Ltd.  Bhubaneswar, India
            </h3>
          </div>
        </div>
        <div className="tag4_a_container">
          <Contactform text_color='black'/>
        </div>
      </div>


    </>
  )
}
