import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer'
import  './Contact.css'
import Contactform from '../components/Contactform';
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function Contact() {
  return (
    <>
   <Header/>
   <div className='one_section_inside_contact_page'>
  <Contactform text_color='white'/>
   </div>
   <WhatsappButton/>
   <CallButton/>
   <Footer/>


   
   
   
   </>
  )
}
