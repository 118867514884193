import React from 'react'
import './Container4.css'
import web_android_graphics_digital_marketing_images from '../assets/images/web_android_graphics_digital_marketing_images.png'
export default function Container4() {
  return (
    <>
      <div className='one_section_container4'>
        <h1 className='a_h1_inside_one_section_container4'>About Us</h1>
        <div className='two_inside_one_section_container4'>
          <p >
            Welcome to Glasshopeone Technologies Private Limited, where  innovation meets excellence. We are a dynamic and forward-thinking IT  company dedicated to transforming your business ideas into digital  realities. Our expertise spans across Website development, Android app  development, Graphics design, and Digital marketing, making us a  one-stop solution for all your technological needs.
          </p>

        </div>

      </div>
      <div className='two_section_container4'>
        <div className='first_two_section_container4'>
          <p>At Glasshopeone Technologies, our vision is to empower  businesses of all sizes with cutting-edge technology solutions  that drive growth and success. We believe in the power of  technology to create a better future, and our mission is to make  that future accessible to everyone.</p>
        </div>
        <div className='second_two_section_container4'>
          <h1>Our Vision</h1>
        </div>
      </div>

      <div className='three_section_container4'>

        <div className='first_three_section_container4'>
          <h1>Our Mission</h1>
        </div>
        <div className='second_three_section_container4'>
          <p>
            Our mission is to deliver top-notch, tailored technology solutions that  align with our clients’ unique needs and goals. We strive to exceed  expectations by providing exceptional service, innovative designs, and  robust development practices. We are committed to helping our clients  succeed in today’s competitive digital landscape.
          </p>
        </div>
      </div>
      <div className='fourth_section_container4'>
        <div className='one_inside_fourth_section_container4'>
          <h1>What We Do</h1>
        </div>
        <div className='two_inside_fourth_section_container4'>
          <img src={web_android_graphics_digital_marketing_images} alt="web_android_graphics_digital_marketing_images" />
        </div>
      </div>
      <div className='fifth_section_container4'>
        <h1 className='a_h1_fifth_section_container4'>
          Website Development
        </h1>
        <p className='a_p_fifth_section_container4'>
          Crafting user-friendly and responsive websites that not only  look stunning but also deliver a seamless experience across all devices.
        </p>
        <h1 className='a_h1_fifth_section_container4'>
          Android App Development
        </h1>
        <p className='a_p_fifth_section_container4'>
          Building powerful and scalable Android applications with  intuitive user interfaces, leveraging the latest technologies like Java, XML, and  Firebase.
        </p>
        <h1 className='a_h1_fifth_section_container4'>
          Graphics Design
        </h1>
        <p className='a_p_fifth_section_container4'>
          Creating visually compelling designs that capture your brand’s  essence and communicate your message effectively.
        </p>
        <h1 className='a_h1_fifth_section_container4'>
          Digital Marketing
        </h1>
        <p className='a_p_fifth_section_container4'>
          Enhancing your online presence through strategic digital marketing  campaigns that drive engagement and conversions.
        </p>
      </div>
      <div className='sixth_section_container4'>
        <div className='one_inside_sixth_section_container4'>
          <h1>
            Our Journey
          </h1>
        </div>

        <div className='two_inside_sixth_section_container4'>
          <p>  Glasshopeone Technologies Private Limited was founded with a simple  yet ambitious goal: to bridge the gap between innovative technology and business success. We  are proudly registered with the Ministry of Corporate Affairs, Government of India which  reflects our commitment to operating with the highest standards of professionalism and  integrity.We have our Registered Office at Bhubaneswar,Odisha,India.  Since our inception, we have grown into a trusted partner for businesses looking to leverage  technology for growth and innovation. Our journey has been marked by continuous learning,  adaptation, and an unwavering dedication to delivering excellence in every project we undertake.</p>
        </div>
      </div>

      <div className='seventh_section_container4'>
         <div className='one_inside_seventh_section_container4'>
         <h1>Join Us on This Journey</h1>
         </div>
         <div className='two_inside_seventh_section_container4'>
          <p> As we continue to grow and evolve, we invite you to join us on this exciting  journey. Whether you’re a startup looking to make your mark or an  established business seeking to innovate, Glasshopeone Technologies is here  to help you achieve your goals.</p>
         </div>
      </div>







    </>
  )
}
