import React from 'react'
import './Container3.css'
import logo_white_transparent from '../assets/images/logo_white_transparent.png'
import computer_coding_image from '../assets/images/computer_coding_image.png'
export default function Container3() {
  return (
   <>
   <div className='one_section_container3'>
    <img src={computer_coding_image} alt="computer_coding_image" className='img1_inside_one_section_container3' />
    <img src={logo_white_transparent} alt="logo_white" className='img2_inside_one_section_container3'/>
    <h2 className='a_h2_inside_one_section_container3'> www.glasshopeone.com</h2>
   </div>
   
   
   
   </>
  )
}
