import React from 'react'
import {
  Link
} from "react-router-dom";
import './Footer.css'
import logo_white_transparent from '../assets/images/logo_white_transparent.png'
import facebook_logo from '../assets/images/facebook_logo.svg'
import youtube_logo from '../assets/images/youtube_logo.svg'
import twitter_x_logo from '../assets/images/twitter_x_logo.svg'
import linkdin_logo from '../assets/images/linkdin_logo.svg'
import instgram_logo from '../assets/images/instagram_logo.svg'
export default function Footer() {
  return (
    <>
      <div className='container1_footer'>
        <div className='one_inside_container1_footer'>
          <img src={logo_white_transparent} alt="logo_glasshopeone" />
        </div>
        <h2 className='two_h2_inside_container1_footer'>Get Ready to Elevate you Business to the next level with  us.</h2>
        <div className='three_inside_container1_footer'>
          <div className='one_three_inside_container1_footer'>
            <h1>
              Office Address:
            </h1>
            <p>
              Plot No- 9N16,4th Floor,Cowork Venue,New Annex Building,  Arch  Bishop’s House, Satyanagar,Bhubaneswar, 751007,Odisha,India
            </p>
          </div>
          <div className='two_three_inside_container1_footer'>
            <h2 className='a_h2_two_three_inside_container1_footer'>Contact</h2>
            <h2 className='b_h2_two_three_inside_container1_footer'> contact@glasshopeone.com</h2>
            <h2 className='c_h2_two_three_inside_container1_footer'> Phone no: +91  7852965155</h2>
            <div className='d_inside_container_two_three_inside_container1_footer'>
              <Link to=""><img src={facebook_logo} alt="facebook_logo" /></Link>
              <Link to=""><img src={linkdin_logo} alt="linkdin_logo" /> </Link>
              <Link to=""><img src={instgram_logo} alt="instgram_logo" /></Link>
              <Link to=""><img src={twitter_x_logo} alt="twitter_x_logo" /></Link>
              <Link to=""><img src={youtube_logo} alt="youtube_logo" /></Link>
            </div>
          </div>

        </div>
        <div className='fourth_inside_container1_footer'>
          <Link to="" className='link_inside_fourth_inside_container1_footer'>Privacy Policy</Link>
          <Link to="" className='link_inside_fourth_inside_container1_footer'>Terms & Condition</Link>
          <Link to="/portfolio" className='link_inside_fourth_inside_container1_footer'>Portfolio</Link>
          <Link to="" className='link_inside_fourth_inside_container1_footer'>Disclaimer</Link>
        </div>
        <div className='fifth_inside_container1_footer'>
          <h1>Copyright(c)2024||Glasshopeone Technologies Private Limited</h1>
        </div>



      </div>



    </>
  )
}
