import React from 'react'
import Container15 from '../components/Container15'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function RealestateProjects() {
  return (
   <>
   <Header/>
   <Container15/>
   <Footer/>
   
   
   </>
  )
}
