import React from 'react'
import './Container12.css'
// import correct_sign from '../assets/images/correct_sign.png'
// import wrong_sign from '../assets/images/wrong_sign.png'
export default function Container12() {
   return (
      <>
         <div className='one_section_container6'>
            <h1 className='first_1_h1_container6'>Website Maintenance Service</h1>
            <div className='one_inside_one_section_container6'>
               <h1 >Our Packages</h1>
            </div>
            <div className='two_inside_one_section_container6'>
               <table className='table_inside_two_inside_one_section_container6'>
                  <tr className='first_row_table_two_inside_one_section_container6'>
                     <th>Package</th>
                     <th>Price INR (₹/Month)</th>
                     <th>Price USD ($/Month)</th>
                     <th>Services Included</th>
                     <th>Recommended for</th>


                  </tr>
                  <tr className='second_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Basic</th>
                     <td>₹5,000</td>
                     <td> $150  </td>
                     <td>
                        <ul>
                           <li> Security updates & patches</li>
                           <li>Monthly website backups</li>
                           <li>Performance monitoring  </li>
                           <li>Minor bug fixes (up to 3/month)</li>
                           <li>Email support</li>
                        </ul>
                     </td>
                     <td>Small businesses with simple static websites.</td>


                  </tr>
                  <tr className='third_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Silver</th>
                     <td>₹10,000</td>
                     <td>  $300  </td>
                     <td>
                     <ul>
                           <li>All Basic features</li>
                           <li> Weekly backups </li>
                           <li>Database maintenance (MongoDB) </li>
                           <li>Content updates up to 5 hours/month</li>
                           <li>Monthly performance reports </li>
                           <li>Priority email support</li>
                          
                        </ul>
                     </td>
                     <td>  Growing businesses with dynamic websites and frequent updates.</td>


                  </tr>
                  <tr className='fourth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Gold</th>
                     <td>  ₹20,000</td>
                     <td>  $600</td>
                     <td> 
                        <ul>
                           <li>All Silver features </li>
                           <li>Daily backups</li>
                           <li> Regular security audits</li>
                           <li>Speed optimization </li>
                           <li>Content/design updates (up to 10 hours/month)</li>
                           <li> SEO assistance</li>
                           <li> 24/7 emergency support </li>
                        </ul>
                     </td>
                     <td>
                     Medium-sized dynamic websites needing frequent updates and security.
                     </td>
                  </tr>
                  <tr className='fifth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Diamond</th>
                     <td> ₹35,000</td>
                     <td> $1200</td>
                     <td> 
                        <ul>
                           <li>All Gold features</li>
                           <li>Custom database scaling MongoDB</li>
                           <li>Full-stack updates (React, Node.js)</li>
                           <li> Integration of new features</li>
                           <li>UI/UX enhancements</li>
                           <li>AWS optimization</li>
                           <li>Dedicated account manager</li>
                        </ul>
                     </td>
                     <td>Large businesses or eCommerce platforms needing premium support.</td>


                  </tr>
                  <tr className='sixth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> Custom</th>
                     <td> Based on requirements </td>
                     <td> Based on requirements</td>
                     <td> 
                        <ul>
                           <li>Tailored services based on business needs</li>
                           <li> Full-stack custom development (React, Node.js, MongoDB)</li>
                           <li>Custom AWS architecture </li>
                           <li>Advanced analytics</li>
                           <li>Dedicated support team</li>
                        </ul>
                     </td>
                     <td>
                     Businesses with unique, large-scale, or highly customized needs.
                     </td>


                  </tr>
                 
               </table>
            </div>

         </div>




      </>
   )
}
