import React from 'react'
import './Container8.css'
import Contactform from './Contactform'
export default function Container8() {
  return (
   <>
   <div className='one_section_container8'>
    <div className='first_inside_one_section_container8'>
    <Contactform text_color='white'/>
    </div>
   
   </div>
   
   
   
   
   </>
  )
}
