import React from 'react'
import './AndroidAppDevelopment.css'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Container9 from '../components/Container9'
import Container10 from '../components/Container10'
import Component11 from '../components/Component11'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function AndroidAppDevelopment() {
  return (
    <>
    <Header/>
   <Container9/>
   <Container10/>
   <Component11/>
   <WhatsappButton/>
   <CallButton/>
    <Footer/>
    
    
    </>
  )
}
