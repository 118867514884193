import React from 'react'
import './WhatsappButton.css'
export default function WhatsappButton() {
    
        const phoneNumber = "7852965155";
  return (
    <div>
      <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
    </div>
  )
}

