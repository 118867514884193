import React from 'react'
import './Container7.css'
import img_Frame_a from '../assets/images/img_Frame_a.png'
import img_Frame_b from '../assets/images/img_Frame_b.png'
import img_Frame_c from '../assets/images/img_Frame_c.png'
import img_Frame_d from '../assets/images/img_Frame_d.png'
import img_Frame_e from '../assets/images/img_Frame_e.png'
import man_img11 from '../assets/images/man_img11.png'
export default function Container7() {
  return (
    <>
      <div className='one_section_container7'>
        <h1 className='first_inside_one_section_container7'> Important Notes :</h1>
        <div className='second_inside_one_section_container7'>
          <div className='one_inside_second_inside_one_section_container7'>
            <img src={img_Frame_a} alt="" />
            <img src={img_Frame_b} alt="" />
            <img src={img_Frame_c} alt="" />
            <img src={img_Frame_d} alt="" />
            <img src={img_Frame_e} alt="" />
          </div>
          <div className='two_inside_second_inside_one_section_container7'>
            <img src={man_img11} alt="" />
          </div>
        </div>


      </div>






    </>
  )
}
