import React from 'react'
import './Container10.css'
// import correct_sign from '../assets/images/correct_sign.png'
// import wrong_sign from '../assets/images/wrong_sign.png'
export default function Container10() {
   return (
      <>
         <div className='one_section_container6'>
            <div className='one_inside_one_section_container6'>
               <h1 >Our Packages</h1>
            </div>
            <div className='two_inside_one_section_container6'>
               <table className='table_inside_two_inside_one_section_container6'>
                  <tr className='first_row_table_two_inside_one_section_container6'>
                     <th>Feature</th>
                     <th>Basic</th>
                     <th>Silver</th>
                     <th>Gold</th>
                     <th>Diamond</th>
                     <th>Custom</th>
                  </tr>
                  <tr className='second_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Suitable for</th>
                     <td>Startups, small   businesses</td>
                     <td> Small to   mid-sized   businesses</td>
                     <td>Growing   businesses</td>
                     <td>Established   businesses,   enterprises</td>
                     <td> Custom  Android app  for specific  business  needs</td>
                  </tr>
                  <tr className='third_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Price(INR)</th>
                     <td>  ₹50,000 - ₹80,000</td>
                     <td> ₹1,00,000 - ₹1,50,000</td>
                     <td>  ₹2,00,000 - ₹3,50,000</td>
                     <td> ₹4,00,000 -   ₹6,00,000+</td>
                     <td>Based on   requirements</td>
                  </tr>
                  <tr className='fourth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Price(USD)</th>
                     <td>  $1000- $2000 </td>
                     <td>  $3000- $5000</td>
                     <td> $7000-  $9000</td>
                     <td>  $11000-  $15000+</td>
                     <td>As Agreed</td>
                  </tr>
                  <tr className='fifth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Custom Design</th>
                     <td> Basic   UI/UX</td>
                     <td> Semi-custom   UI/UX </td>
                     <td> Full custom   UI/UX</td>
                     <td> Advanced   custom   UI/UX</td>
                     <td>100% tailored   UI/UX</td>
                  </tr>
                  <tr className='sixth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> App  Pages/Screens</th>
                     <td> Up to 5   screens </td>
                     <td> Up to 10   screens</td>
                     <td> Up to 15   screens </td>
                     <td>As Required</td>
                     <td>As Required</td>
                  </tr>
                  <tr className='seventh_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> App   Publishing</th>
                     <td>Google  Play   Store   setup</td>
                     <td> Play Store  setup + Optimization</td>
                     <td> Play Store   with   ASO</td>
                     <td> Play Store   with  advanced   ASO</td>
                     <td>Custom   deployment   strategy</td>
                  </tr>
                  <tr className='eighth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> App   Performance</th>
                     <td> Basic  performance  optimization </td>
                     <td> Enhanced  optimization</td>
                     <td> Advanced  performance  tuning  </td>
                     <td> Full  performance  suite</td>
                     <td> Tailored to app  requirements</td>
                  </tr>
                  <tr className='ninenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> Security  Features</th>
                     <td> Basic  security   setup</td>
                     <td>Enhanced  security </td>
                     <td> Advanced  security   features</td>
                     <td> Comprehensive  security</td>
                     <td> Custom  security  solutions</td>
                  </tr>
                  <tr className='tenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> Database  Integration</th>
                     <td> Basic local DB</td>
                     <td> Cloud  database integration</td>
                     <td> Advanced  cloud DB  setup</td>
                     <td> Full  cloud-based  solution</td>
                     <td> As per project   requirements</td>
                  </tr>
                  <tr className='eleventh_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Third-party  API  Integration</th>
                     <td> Basic  integration </td>
                     <td>Multiple API   integrations</td>
                     <td> Advanced   third-party   API   setup</td>
                     <td> Full-scale  API   integration</td>
                     <td>Tailored API   requirements</td>
                  </tr>
                  <tr className='twelveth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> Support &   Maintenance</th>
                     <td> 1 month post-launch  support </td>
                     <td> 3 months  post-launch  support</td>
                     <td> 3 months  post-launch  support</td>
                     <td> 3 months  post-launch  support</td>
                     <td> Custom   support plans</td>
                  </tr>
                  <tr className='thirteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Additional Screens</th>
                     <td> $50 per  additional page</td>
                     <td>$45 per  additional  page</td>
                     <td>$40 per  additional  page </td>
                     <td> $30 per   additional  page</td>
                     <td> As required</td>
                  </tr>
                  <tr className='thirteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> Analytics  Setup</th>
                     <td> Basic  analytics  setup </td>
                     <td> Analytics +  reporting </td>
                     <td> Advanced  analytics  &  custom  reports </td>
                     <td> Full analytics  suite  </td>
                     <td> Custom KPIs  and insights   </td>
                  </tr>
                  <tr className='fouteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Time to Deliver</th>
                     <td> 4-6 weeks</td>
                     <td> 6-10 weeks</td>
                     <td> 8-16 weeks</td>
                     <td>16 to 24 Weeks</td>
                     <td>As greed</td>
                  </tr>
                  <tr className='fifteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Add-Ons &  Customizations</th>
                     <td> Available at  extra cost </td>
                     <td> Available at extra cost </td>
                     <td> Fully  customizable   </td>
                     <td>  Fully  customizable   </td>
                     <td> Tailored to  client needs</td>
                  </tr>



               </table>
            </div>

         </div>




      </>
   )
}
