import React from 'react'
import Header from '../components/Header'
import Container3 from '../components/Container3'
import Container4 from '../components/Container4'
import Footer from '../components/Footer'
import './About.css'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function About() {
  return (
   <>
 <Header/>
 <div className='one_container_in_page_about'>
 <Container3/>
 <Container4/>
 </div>
 <WhatsappButton/>
 <CallButton/>

<Footer/>

   </>
     
    
  )
}
