import React from 'react'
import './Container6.css'
import correct_sign from '../assets/images/correct_sign.png'
import wrong_sign from '../assets/images/wrong_sign.png'
export default function Container6() {
   return (
      <>
         <div className='one_section_container6'>
            <div className='one_inside_one_section_container6'>
               <h1 >Our Packages</h1>
            </div>
            <div className='two_inside_one_section_container6'>
               <table className='table_inside_two_inside_one_section_container6'>
                  <tr className='first_row_table_two_inside_one_section_container6'>
                     <th>Feature</th>
                     <th>Basic</th>
                     <th>Silver</th>
                     <th>Gold</th>
                     <th>Diamond</th>
                     <th>Custom</th>
                  </tr>
                  <tr className='second_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Suitable for</th>
                     <td>Startups, small   businesses</td>
                     <td> Small to   mid-sized   businesses</td>
                     <td>Growing   businesses</td>
                     <td>Established   businesses,   enterprises</td>
                     <td>Custom Web   app for   specific   business   needs</td>
                  </tr>
                  <tr className='third_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Price(INR)</th>
                     <td> 25000-50,000</td>
                     <td>75,000-   1,50,000</td>
                     <td> 1,75000-   3,00,000</td>
                     <td>3,50,000-   5,00,000</td>
                     <td>Based on   requirements</td>
                  </tr>
                  <tr className='fourth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Price(USD)</th>
                     <td> $ 450-1000 </td>
                     <td> $ 1500   -2500</td>
                     <td>$3500- 4500</td>
                     <td> $5000-   8500</td>
                     <td>As Agreed</td>
                  </tr>
                  <tr className='fifth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Custom Design</th>
                     <td>Template-based Design</td>
                     <td>Semi-custom Design </td>
                     <td>Custom Design </td>
                     <td>Fully custom Design</td>
                     <td>100%  tailored Design</td>
                  </tr>
                  <tr className='sixth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Number of Pages</th>
                     <td>Up to 5 pages </td>
                     <td>Up to 10   pages</td>
                     <td>Up to 15   pages </td>
                     <td>As Required</td>
                     <td>As Required</td>
                  </tr>
                  <tr className='seventh_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Responsive Design</th>
                     <td><img src={correct_sign} alt="" /></td>
                     <td><img src={correct_sign} alt="" /></td>
                     <td><img src={correct_sign} alt="" /></td>
                     <td><img src={correct_sign} alt="" /></td>
                     <td><img src={correct_sign} alt="" /></td>
                  </tr>
                  <tr className='eighth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>SEO Optimization</th>
                     <td>Basic SEO setup </td>
                     <td>Enhanced   SEO setup  </td>
                     <td>Advanced  SEO strategy   </td>
                     <td>Full SEO   strategy &   ongoing   optimization</td>
                     <td>As Required</td>
                  </tr>
                  <tr className='ninenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>E Commerce Integration</th>
                     <td><img src={wrong_sign} alt="" /></td>
                     <td>Optional Add on </td>
                     <td><img src={correct_sign} alt="" /></td>
                     <td><img src={correct_sign} alt="" /></td>
                     <td><img src={correct_sign} alt="" /></td>
                  </tr>
                  <tr className='tenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Hosting & Domain</th>
                     <td>Domain and   hosting charges are the client’s   responsibility</td>
                     <td>Domain and   hosting charges are the client’s   responsibility</td>
                     <td>Domain and   hosting charges are the client’s   responsibility</td>
                     <td>Domain and   hosting charges are the client’s   responsibility</td>
                     <td>Domain and   hosting charges are the client’s   responsibility</td>
                  </tr>
                  <tr className='eleventh_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Support & Maintenance</th>
                     <td>1 month post-launch  support </td>
                     <td>3 months   post-launch   support</td>
                     <td>3 months   post-launch   support</td>
                     <td>3 months   post-launch   support</td>
                     <td>Custom   support plans</td>
                  </tr>
                  <tr className='twelveth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Security Features</th>
                     <td> Basic security   setup </td>
                     <td>Enhanced   security features</td>
                     <td>Advanced   security & regular   updates</td>
                     <td>Comprehensive  security &   monitoring</td>
                     <td>Tailored   security   solutions</td>
                  </tr>
                  <tr className='thirteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Additional Pages</th>
                     <td> $50 per  additional page</td>
                     <td>$45 per  additional  page</td>
                     <td>$40 per  additional  page </td>
                     <td> $30 per   additional  page</td>
                     <td> As required</td>
                  </tr>
                  <tr className='thirteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Adds-on & Customization</th>
                     <td>Available at  extra cost </td>
                     <td>Available at extra cost </td>
                     <td>Available at extra cost </td>
                     <td>Fully  customizable   </td>
                     <td>Fully  customizable   </td>
                  </tr>
                  <tr className='fouteenth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Time to Deliver</th>
                     <td>3 to 4  Weeks</td>
                     <td>4 to 8 Weeks</td>
                     <td>8 to 12 Weeks</td>
                     <td>16 to 20 Weeks</td>
                     <td>As greed</td>
                  </tr>



               </table>
            </div>

         </div>




      </>
   )
}
