import React from 'react'
import './WebsiteDevelopment.css'
import { Helmet } from 'react-helmet';
import Header from '../components/Header'
import Container5 from '../components/Container5'
import Container6 from '../components/Container6'
import Container7 from '../components/Container7'
import Container8 from '../components/Container8'
import Footer from '../components/Footer'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function WebsiteDevelopment() {
  return (
    <>
    <Helmet>
        <title>Glasshopeone Technologies Pvt. Ltd.,Bhubaneswar,India</title>
        <meta name="description" content="Best Website and Android App development Company in Bhubaneswar,India" />
        <meta name="keywords" content="website development company in bhubaneswar,website development in bhubaneswar,best website development company in odisha,website developer,react website developer,best web app development company in bhubaneswar
        ,glasshopeone,glasshopeone technology,glasshopeone terchnologies,glasshopeone technologies private limited,glasshope" />
        <meta name="author" content="Glasshopeone Technologies Private Limited" />
        <link rel="canonical" href="https://www.yourwebsite.com/path" />
      </Helmet>
    <Header/>
    <Container5/>
    <Container6/>
    <Container7/>
    <Container8/>
    <WhatsappButton/>
    <CallButton/>
    <Footer/>
    
    
    </>
  )
}
