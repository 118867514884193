import React from 'react'
import './DemoComponent.css'
export default function DemoComponent() {
  return (
   <>
   <div className='one_section_demoComponent'>
         <h1>Will be Updated Soon</h1>
   </div>
   
   
   
   </>
  )
}
