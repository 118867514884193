import React from 'react'
import { useState } from 'react';
import './Contactform.css'

export default function Contactform({text_color}) {
  //create state to store form data
  const [formData, SetFormData] = useState({
    name: '',
    email: '',
    phone: '',
    services: '',
    message: '',
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    SetFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const  goole_sheet_backend_url = 'https://script.google.com/macros/s/AKfycbzCQOVXdOG3xzALuEMlMPvfG0Sal3ZRWy9lIyAEkwe2DPKrp0MI66GeOXxYg5vdHAzs/exec';

     // Create URL-encoded form body
     const formBody = new URLSearchParams();
     formBody.append('name', formData.name);
     formBody.append('email', formData.email);
     formBody.append('phone', formData.phone);
     formBody.append('services', formData.services);
     formBody.append('message', formData.message);

    // Submitting the form data to Google Form
    fetch(goole_sheet_backend_url, {
      method: 'POST',
      mode: 'no-cors',  // No-CORS mode because we're sending data to a different domain
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody.toString(),
    })
      .then(() => {
        alert('Thank you for contacting with us, We will get back to you in some time!');
        SetFormData({
          name: '',
          email: '',
          phone: '',
          services: '',
          message: '',
        });
      })
      .catch((error) => {
        alert('Error submitting the Contact form!');
        console.error(error);
      });
  };


return (
  <>
    <div className="form_container">
      <h2 className='form_h2_a' style={{color:text_color}}>Connect with us</h2>
      <form onSubmit={handleSubmit} className='form_inside_1_form_container'>
        <div className='form_label_input_container_1_form_container'>
          <label htmlFor="name" className='form_label_name' style={{color:text_color}}>Name*</label>
          <input
            className='form_input_name'
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleOnChange}
            required

          />
        </div>
        <div className='form_label_input_container_1_form_container'>
          <label htmlFor="email" className='form_label_email' style={{color:text_color}}>Email*</label>
          <input
            className='form_input_email'
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className='form_label_input_container_1_form_container'>
          <label htmlFor="phone" className='form_label_phone' style={{color:text_color}}>Phone*</label>
          <input
            className='form_input_phone'
            typeof='tel'
            id='phone'
            name='phone'
            value={formData.phone}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className='form_label_input_container_1_form_container'>
          <label htmlFor="services" className='form_label_services' style={{color:text_color}}>Services*</label>
          <select
            className='form_select_services'
            name="services"
            id="services"
            value={formData.services}
            onChange={handleOnChange}
            required
          >
            <option value="">-------Select a Service-------</option>
            <option value="webdev">Website Development</option>
            <option value="androiddev">Android App Development</option>
            <option value="bothwebandroid">Both Website and Android App Development</option>

          </select>
        </div>


        <div className='form_label_input_container_1_form_container'>
          <label htmlFor="message" className='form_label_message' style={{color:text_color}}>Message:</label>
          <textarea
            className='form_textarea_message'
            id="message"
            name="message"
            value={formData.message}
            onChange={handleOnChange}
            
          />
        </div>
        <button type="submit" className='form_sendmessage_button'>Submit</button>
      </form>





    </div>


  


  </>
  
)
}
