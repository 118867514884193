
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import OurServices from './pages/OurServices';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Career from './pages/Career'
import WebsiteDevelopment from './pages/WebsiteDevelopment';
import AndroidAppDevelopment from './pages/AndroidAppDevelopment';
import WebsiteMaintenance from './pages/WebsiteMaintenance';
import AndroidAppMaintenance from './pages/AndroidAppMaintenance';
import Portfolio from './pages/Portfolio';

import RealestateProjects from './pages/RealestateProjects';


const router = createBrowserRouter([
  {
    path: "/",
    element:<Home/>
  },
  {
    path: "/about",
    element: <About/>
    
  },
  {
    path:"/services",
    element:<OurServices/>
  },
  {
    path:"/blog",
    element:<Blog/>
  },
  {
    path:"/career",
    element:<Career/>
  },
  {
    path:"/contact",
    element:<Contact/>
  },{
    path:"/website-development-service",
    element:<WebsiteDevelopment/>
  },
  {
    path:"/android-app-development-service",
    element:<AndroidAppDevelopment/>
  },
  {
    path:"/website-maintenance-service",
    element:<WebsiteMaintenance/>
  },
  {
    path:"/android-app-maintenance-service",
    element:<AndroidAppMaintenance/>
  },
  {
    path:"/portfolio",
    element:<Portfolio/>
  },
  {
    path:"/realestate-projects",
    element:<RealestateProjects/>
  }

]);


function App() {
  return (
   <>


   <RouterProvider router={router} />
   </>
  );
}

export default App;
