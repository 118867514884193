import React from 'react'
import './Container9.css'
import android_service_development_1 from '../assets/images/android_service_development_1.png'
export default function Container9() {
  return (
    <>
      <div className='one_section_container9'>
        <div className='one_inside_one_section_container9'>
          <h1>Our Services</h1>
          <h2>Android App Development</h2>
          <p>In today's digital landscape, a robust and engaging mobile  application can set your business apart from the competition. At  Glasshopeone Technologies, we specialize in delivering  outstanding native Android applications that cater to a wide range  of business needs. Whether you’re launching a startup or scaling  an established enterprise, our dedicated team of experts is  committed to developing high-quality, impactful mobile solutions  that drive user engagement and streamline your operations.</p>
          <p>Our development approach is rooted in understanding your  specific goals and translating them into intuitive, efficient, and  visually appealing applications. We leverage the latest  technologies and best practices to ensure your app not only  meets but exceeds industry standards.</p>
        </div>
        <div className='two_inside_one_section_container9'>
          <img src={android_service_development_1} alt="Android App Development Service" />
          <h1>Android App Development</h1>
        </div>
        <div className='three_inside_one_section_container9'>
          <h1>Technologies We Use :</h1>
        </div>
        <div className='four_inside_one_section_container9'>
          <div className='first_inside_four_inside_one_section_container9'>
            <h2>Frontend</h2>
            <div className='card_inside_first_inside_four_inside_one_section_container9'>
              <ul>
                <li>Android Studio</li>
                <li>Java</li>
                <li>XML</li>
              </ul>
            </div>
          </div>
          <div className='first_inside_four_inside_one_section_container9'>
            <h2>Backend</h2>
            <div className='card_inside_first_inside_four_inside_one_section_container9'>
              <ul>
                <li>Firebase</li>
                <li>Node</li>
                <li>MongoDB</li>
              </ul>
            </div>
          </div>
          <div className='first_inside_four_inside_one_section_container9'>
            <h2>Deployment</h2>
            <div className='card_inside_first_inside_four_inside_one_section_container9'>
              <ul>
                <li>Google Play Console</li>
                <li> AWS (Amazon Web Services)</li>
                <li> Firebase (for Backend Server)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='five_inside_one_section_container9'>
          <h1>Frontend</h1>
        </div>
        <div className='sixth_inside_one_section_container9'>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2> Android Studio</h2>
            <p>The official Integrated Development Environment (IDE)  for Android app development, providing robust tools for building, testing,  and debugging apps.</p>

          </div>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2>Java</h2>
            <p> The primary programming language for Android development,  known for its versatility and wide range of libraries and frameworks.</p>
          </div>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2>XML</h2>
            <p>Utilized for designing and defining the layout and user interface  elements of Android applications, ensuring a seamless and responsive  design.</p>
          </div>
        </div>
        <div className='five_inside_one_section_container9'>
          <h1>Backend</h1>
        </div>
        <div className='sixth_inside_one_section_container9'>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2> Firebase</h2>
            <p>A comprehensive platform that offers a suite of backend  services, including real-time databases, authentication, and cloud storage,  enabling rapid development and scaling of apps.</p>

          </div>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2>Node.js</h2>
            <p> A JavaScript runtime built on Chrome's V8 engine, used for  building scalable and efficient server-side applications, enhancing the  performance of the backend.</p>

          </div>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2>MongoDB</h2>
            <p>A NoSQL database known for its flexibility and scalability, ideal  for managing complex and dynamic data structures in the backend.</p>
          </div>
        </div>
        <div className='five_inside_one_section_container9'>
          <h1>Deployment</h1>
        </div>
        <div className='sixth_inside_one_section_container9'>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2> Google Play Console</h2>
            <p>The platform used for publishing and managing  Android applications on the Google Play Store, providing tools for app  distribution, performance monitoring, and user feedback</p>

          </div>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2> AWS (Amazon Web Services)</h2>
            <p>  A cloud computing service offering a  range of infrastructure and platform services, used for deploying and  managing scalable and reliable backend servers.</p>
          </div>
          <div className='one_inside_sixth_inside_one_section_container9'>
            <h2> Firebase (for Backend Server)</h2>
            <p>Complements AWS by offering additional  backend services, including cloud functions, real-time data  synchronization, and analytics, ensuring a robust backend infrastructure.</p>
          </div>
        </div>
        

      </div>



    </>
  )
}
