import React from 'react';
import ReactPlayer from 'react-player';
import './Container15.css';

import nestconestate_screen1 from '../assets/images/nestconestate_screen1.jpg';
import nestconestate_screen2 from '../assets/images/nestconestate_screen2.jpg';
import nestconestate_screen3 from '../assets/images/nestconestate_screen3.jpg';
import nestconestate_screen4 from '../assets/images/nestconestate_screen4.jpg';
import nestconestate_screen5 from '../assets/images/nestconestate_screen5.jpg';
import nestconestate_screen6 from '../assets/images/nestconestate_screen6.jpg';
import nestconestate_screen7 from '../assets/images/nestconestate_screen7.jpg';
import nestconestate_screen8 from '../assets/images/nestconestate_screen8.jpg';
import nestconestate_screen9 from '../assets/images/nestconestate_screen9.jpg';
import nestconestate_screen10 from '../assets/images/nestconestate_screen10.jpg';
import nestconestate_screen11 from '../assets/images/nestconestate_screen11.jpg';
import nestconestate_screen12 from '../assets/images/nestconestate_screen12.jpg';
import nestconestate_screen13 from '../assets/images/nestconestate_screen13.jpg';
import nestconestate_screen14 from '../assets/images/nestconestate_screen14.jpg';
import home_page_nestconestate_1_gho from '../assets/images/home_page_nestconestate_1_gho.svg';
import about_us_page_nestconestate_2gho from '../assets/images/about_us_page_nestconestate_2gho.svg';
import contact_page_nestconestate_3_gho from '../assets/images/contact_page_nestconestate_3_gho.svg';
export default function Container15() {
  return (
    <>
    <div className='one_container_1_Container15'>
     <h1>Android App(Realestate Project)</h1>
     <div className='first_inside_one_container_1_Container15'>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen1} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen2} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen3} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen4} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen5} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen6} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen7} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen8} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen9} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen10} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen11} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen12} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen13} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15'>
       <img src={nestconestate_screen14} alt="" />
      </div>
      <div className='two_inside_first_inside_one_container_1_Container15  nestconestate_android_video'>
      <ReactPlayer
        url="https://youtu.be/ws61zhUMzxQ"
        controls={true} // Shows video controls
        width="390px"    // Set the width of the player
        height="693px"   // Set the height of the player
      />
      </div>
     
     </div>
        <h1>Web Application(Realestate Projects)</h1>
     <div className='second_inside_one_container_1_Container15'>
       <img src={home_page_nestconestate_1_gho} alt="" />
     </div>
     <div className='second_inside_one_container_1_Container15'>
       <img src={about_us_page_nestconestate_2gho} alt="" />
     </div>
     <div className='second_inside_one_container_1_Container15'>
       <img src={contact_page_nestconestate_3_gho} alt="" />
     </div>
    
    </div>
   
    
    
    
    
    
    </>
  )
}
