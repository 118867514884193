import React from 'react'
import './Container13.css'
// import correct_sign from '../assets/images/correct_sign.png'
// import wrong_sign from '../assets/images/wrong_sign.png'
export default function Container13() {
   return (
      <>
         <div className='one_section_container6'>
            <h1 className='first_1_h1_container6'>Android App Maintenance Service</h1>
            <div className='one_inside_one_section_container6'>
               <h1 >Our Packages</h1>
            </div>
            <div className='two_inside_one_section_container6'>
               <table className='table_inside_two_inside_one_section_container6'>
                  <tr className='first_row_table_two_inside_one_section_container6'>
                     <th>Package</th>
                     <th>Price INR (₹/Month)</th>
                     <th>Price USD ($/Month)</th>
                     <th>Services Included</th>
                     <th>Recommended for</th>


                  </tr>
                  <tr className='second_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Basic</th>
                     <td>₹7,000</td>
                     <td> $180  </td>
                     <td>
                        <ul>
                           <li> Bug fixes up to 3 issues/month</li>
                           <li>Security patches & update </li>
                           <li>Google Play Console monitoring </li>
                           <li>Minor bug fixes (up to 3/month)</li>
                           <li>Monthly app performance report</li>
                           <li>Email support</li>
                        </ul>
                     </td>
                     <td>Small apps with minimal updates and maintenance needs.</td>


                  </tr>
                  <tr className='third_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Silver</th>
                     <td>₹15,000</td>
                     <td>  $360  </td>
                     <td>
                     <ul>
                           <li>All Basic features</li>
                           <li> Monthly app version updates </li>
                           <li>Database optimization </li>
                           <li>UI/UX adjustments (minor)</li>
                           <li>Crash monitoring & fixes</li>
                           <li>Priority email support</li>
                          
                        </ul>
                     </td>
                     <td>  Growing apps with dynamic features and moderate maintenance needs.</td>


                  </tr>
                  <tr className='fourth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Gold</th>
                     <td>  ₹30,000</td>
                     <td>  $720</td>
                     <td> 
                        <ul>
                           <li>All Silver features </li>
                           <li>Bi-weekly app version updates</li>
                           <li> Advanced security audits</li>
                           <li>UI/UX improvements</li>
                           <li>App performance optimization</li>
                           <li> 24/7 emergency support </li>
                        </ul>
                     </td>
                     <td>
                     Medium to large apps requiring frequent updates and improvements.
                      </td>
                  </tr>
                  <tr className='fifth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'>Diamond</th>
                     <td> ₹50,000</td>
                     <td> $1500</td>
                     <td> 
                        <ul>
                           <li>All Gold features</li>
                           <li>Weekly app version updates </li>
                           <li>New feature integration</li>
                           <li> Advanced analytics setup</li>
                           <li>Play Store optimization</li>
                           <li>Dedicated support team</li>
                          
                        </ul>
                     </td>
                     <td>Apps with highly specific and unique maintenance requirements.</td>


                  </tr>
                  <tr className='sixth_row_table_two_inside_one_section_container6'>
                     <th className='heading_inside_from_second_row_table_inside_two_inside_one_section_container6'> Custom</th>
                     <td> Based on requirements </td>
                     <td> Based on requirements</td>
                     <td> 
                        <ul>
                           <li>Tailored maintenance plan</li>
                           <li> Full app redevelopment or redesign</li>
                           <li>Play Store account management </li>
                           <li>Continuous feature enhancement </li>
                           <li>Custom security features</li>
                        </ul>
                     </td>
                     <td>
                     Apps with highly specific and unique maintenance requirements.
                     </td>


                  </tr>
                 
               </table>
            </div>

            <h1 className='second_2_h1_container6'>To be Noted</h1>
            <ul className='second_ul_inside_container6'>
               <li>
               Google Play Console account fees are not included in these packages
               </li>
               <li>
               Packages can be customized based on additional feature or scaling needs.

               </li>
            </ul>

         </div>




      </>
   )
}
