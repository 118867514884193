import React from 'react'
import { useState } from 'react';
import logo_white from '../assets/images/logo_white.png';
import arrow_drop_down from '../assets/images/arrow_drop_down.svg'
import {
  Link, useLocation
} from "react-router-dom";
import './Header.css';
export default function Header() {

  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  //Function to toggle visibility of Drop down container
  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      <nav className="navContainer">
        <div className='left_navContainer'>
          <img src={logo_white} alt="logo_white" />

        </div>
        <div className='middle_navContainer'>
          <ul>
            <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link></li>
            <li><Link to="/about" className={location.pathname === '/about' ? 'active' : ''} >About</Link></li>
            {/* <li><Link to="/services" className={location.pathname==='/services' ? 'active' : ''}>Services</Link></li> */}
            <div className='drop_down_a_middle_navContainer'>
              <button className='btn_drop_down_a_middle_navContainer' onClick={toggleVisibility}>
                Services <span><img src={arrow_drop_down} alt="" /></span>
              </button>
              <div className='contents_drop_down_a_middle_navContainer' style={{ display: isVisible ? 'flex' : 'none' }}>
                <li className='chk'><Link to="/website-development-service" className={location.pathname === '/service' ? 'active' : ''}>Website Development</Link></li>
                <li><Link to="/android-app-development-service" className={location.pathname === '/service' ? 'active' : ''}>Android App Development</Link></li>
                <li><Link to="/website-maintenance-service" className={location.pathname === '/service' ? 'active' : ''}>Website Maintenance</Link></li>
                <li><Link to="/android-app-maintenance-service" className={location.pathname === '/service' ? 'active' : ''}>Android App Maintenance</Link></li>
              </div>
            </div>
            <li><Link to="/career" className={location.pathname === '/career' ? 'active' : ''}>Career</Link></li>
            <li><Link to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>Blog</Link></li>

          </ul>
        </div>
        <div className="right_navContainer">
          <button className='contact_btn'>
            <Link to="/contact" className={`text_contact_btn ${location.pathname === '/contact' ? 'active' : ''}`}>Contact Us</Link>

          </button>

        </div>


      </nav>



    </>
  )
}
